<template>
  <div class="item">
    <div class="itemView">
      <div class="name"> {{name}} </div>
      <div class="itemContent">
        <!-- 输入 -->
        <div v-if="type === 1">
          <input
          class="text-input"
          :placeholder="placeholder"
          v-model.trim="change_value" />
        </div>
        <div v-if="type === 2">
          <NumberInput
          :placeholder="placeholder"
          class="text-input"
          v-model="change_value"/>
        </div>
        <div v-if="type === 3" @click="select_status">
          <span class="placeholder" v-if="!change_value">{{placeholder}}</span>
          <span v-else>{{hander_value(change_value)}}</span>
        </div>
        <div v-if="type === 4" class="sms">
          <div class="sms-input">
            <NumberInput
            :decimalDigits="0"
            :integerDigits="6"
            class="text-input"
            :placeholder="placeholder"
            v-model="change_value"/>
          </div>
          <div class="text"><template v-if="time > 0"><van-count-down @finish="finish" format="ss s" :time="time" /></template><span v-else @click="send_sms">发送短信</span></div>
        </div>
        <div v-if="type === 5" @click="select_birthday">
          <span class="placeholder" v-if="!change_value">{{placeholder}}</span>
          <span v-else>{{hander_birthday(change_value)}}</span>
        </div>
      </div>
    </div>
    <van-action-sheet
      v-model="showPicker"
      cancel-text="取消"
      close-on-click-action
    >
    <div class="content" @click="comfire(i)" v-for="i in list" :key="i.value">
      {{i.label}}
    </div>
    </van-action-sheet>
     <van-action-sheet
      v-model="show"
      cancel-text="取消"
      close-on-click-action
    >
      <van-datetime-picker
        @confirm="confirm"
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
      />
     </van-action-sheet>
     <div class="error" v-if="error_obj[value_key]&&error_obj[value_key].error">{{ error_obj[value_key].error }}</div>
  </div>
</template>
<script>
import NumberInput from './NumberInput'
import {get_sms} from './service'
import {format_date} from '../../../utils/format'
export default {
  data () {
    return {
      change_value: null,
      showPicker: false,
      show: false,
      time: 0,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date()
    }
  },
  components: {
    NumberInput
  },
  props: {
    value: {
      default: null,
      type: null
    },
    rules: {
      default: null,
      type: null
    },
    name: {
      default: '',
      type: String
    },
    value_key: {
      default: '',
      type: String
    },
    error_obj: {
      default: () => {},
      type: Object
    },
    placeholder:{
      default: '',
      type: String
    },
    type: {
      default: null,
      type: Number
    },
    list: {
      default: () => [],
      type: Array
    },
    params:{
      default: () => {},
      type: Object
    }
  },
  computed: {
  },
  watch: {
    change_value(v) {
      if(this.rules&&this.rules.required) {
        if(!v) {
          this.error_obj[this.value_key].error = this.rules.message
        } else {
          this.error_obj[this.value_key].error = ''
        }
      } else if(this.rules&&this.rules.validator){
        this.error_obj[this.value_key].error = this.rules.validator(v,this.params.card_type)
      }
      this.$emit('input',v)
    }
  },
  created () {
    this.change_value = this.value
    if(this.type === 5&&this.value) this.currentDate = new Date(this.value)
    if(this.rules) {
      this.error_obj[this.value_key] = {
        rules: this.rules,
        error: '',
      }
    }

  },
  destroyed () {

  },
  mounted () {

  },
  methods: {
    comfire(val) {
      this.change_value = val.value
      this.showPicker = false
    },
    select_status() {
      this.showPicker = true
    },
    hander_value() {
      for(let i of this.list) {
        if(i.value === this.change_value) return i.label
      }
      return ''
    },
    select_birthday() {
      this.show = true
    },
    confirm(val) {
      this.change_value = val.getTime()
      this.show = false
    },

    hander_birthday() {
      return format_date(this.change_value)
    },
    finish() {
      this.time = 0
    },
    async send_sms() {
      let {phone} = this.params
      if(!phone) {
        this.$notify({ type: "warning", message: "请输入手机号" });
        return
      }
      let reg =/^(\+86)?(1[3-9]\d{9})$|^(\+852)?([6|9|5])\d{7}$|^(\+886)?[0][9]\d{8}$|^(\+8536)?\d{7}$/
      if(!reg.test(phone)) {
        this.$notify({ type: "warning", message: "请输入正确的手机号" });
        return
      }
      try {
        this.time = 1*60*1000
        await get_sms({phone})
      } catch (error) {
        this.time = 0
      }
    },
  }
}
</script>
<style scoped>
  .itemView{
    background: #fff;
    padding:16px 24px;
    display: flex;
    align-items: center;
    background: #F1F1F1;
    border-radius: 20px;
  }
  .itemContent{
    min-height: 16px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
    color: #242938;
    flex:1;
  }
  .name{
    font-size: 14px;
    font-weight: 400;
    line-height: 6px;
    color: #242938;
    min-width: 56px;
    margin-right: 13px;
  }
  .error {
    font-size: 12px;
    color: #FB2B4E;
    padding-top: 2px;
    padding-left: 24px;
    position: absolute;
  }
  .text-input {
    width: 100%;
    background: #F1F1F1;
    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      color: rgba(36, 41, 56, 1);
      -webkit-text-fill-color: rgba(36, 41, 56, 1);
      -webkit-opacity:1;
      opacity: 1;
    }
  }
  .item {
    margin-bottom: 16px;
    position: relative;
  }
  .content {
    padding: 16px;
    text-align: center;
  }
  .sms {
    display: flex;
    align-items: center;
    & .sms-input {
      flex: 1;
      background: #F1F1F1;
    }
    & .text {
      width: 65px;
      font-size: 14px;
      font-weight: 400;
      line-height: 7px;
      color: #0088FF;
    }
  }
</style>
