import api from '../utils/api'

// 微信授权
export async function wx_tickets (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {}
  //     })
  //   }, 500)
  // })
  return api.post('/api/v1/mp/luohu/medical_cards/face_sign', payload)
}
