<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="head">
        <div class="title">完善信息领取健康卡</div>
        <div class="info">健康卡支持网上挂号、开药、就诊等便捷服务</div>
      </div>
      <div class="main">
        <div class="main-title">
          录入{{ has_card ? "家人" : "本人" }}信息绑定健康卡
        </div>
        <div class="main-introduct">您的个人信息将会受到安全保护</div>
        <Item
          :type="1"
          name="姓名"
          v-model="data.name"
          value_key="name"
          :error_obj="error_obj"
          :rules="{ required: true, message: '请填写姓名' }"
          placeholder="请输入"
        />
        <Item
          :type="3"
          :list="card_type_list"
          name="证件类型"
          :rules="{ required: true, message: '请选择证件类型' }"
          v-model="data.card_type"
          value_key="card_type"
          :error_obj="error_obj"
          placeholder="请选择"
        />
        <Item
          :type="1"
          name="证件号"
          :rules="{ validator: validator }"
          v-model="data.card_no"
          value_key="card_no"
          :params="data"
          :error_obj="error_obj"
          placeholder="请输入"
        />
        <Item
          :type="3"
          :list="gender_list"
          name="性别"
          :rules="{ required: true, message: '请选择性别' }"
          v-model="data.gender"
          value_key="gender"
          :error_obj="error_obj"
          placeholder="请选择"
        />
        <Item
          :type="5"
          name="出生日期"
          :rules="{ required: true, message: '请选择出生日期' }"
          v-model="data.birthday"
          value_key="birthday"
          :error_obj="error_obj"
          placeholder="请选择"
        />
        <Item
          :type="2"
          name="手机"
          :params="data"
          :rules="{ validator: validator2 }"
          v-model="data.phone"
          value_key="phone"
          :error_obj="error_obj"
          placeholder="请输入"
        />
        <Item
          :type="4"
          name="验证码"
          :params="data"
          :rules="{ required: true, message: '请输入验证码' }"
          v-model="data.sms_code"
          value_key="sms_code"
          :error_obj="error_obj"
          placeholder="请输入"
        />
        <Item
          :type="3"
          :list="has_card ? relationship_list : relationship_list1"
          name="家庭关系"
          :rules="{ required: true, message: '请选择家庭关系' }"
          v-model="data.relationship_type"
          value_key="relationship_type"
          :error_obj="error_obj"
          placeholder="请选择"
        />
        <div class="position">
          <div class="remind">
            <van-icon
              name="question-o"
              class="icon"
            />如帮他人绑卡请选择与本人关系
          </div>
          <div class="btn" @click="submit">立即绑定</div>
          <div class="remind2">
            点击立即绑定，即代表您已同意<span>《用户信息授权协议》</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { params, cardVali } from "./config";
import { to_submit } from "./service";
import Item from "./Item";
import { getSession, setSession } from "../../../utils/session";
import { isWechat } from "../../../utils/validate";
import { wx_tickets } from "../../../common-data/service";

export default {
  data() {
    let validator = (val, type) => {
      if (!val && (val !== "0" || val !== 0)) {
        return "证件号不能为空";
      }
      if (type === "01") {
        let mes = cardVali(val);
        if (mes.status !== 1) {
          return mes.message;
        }
      } else if (type === "15") {
        let reg = /(H|M)(\d{10})$/;
        if (!reg.test(val)) return "请输入正确的证件号";
      } else if (type === "17") {
        let reg = /(^\d{8})$/;
        if (!reg.test(val)) return "请输入正确的证件号";
      } else if (type === "03") {
        let reg =
          /^1[45][0-9]{7}|([P|p|S|s]\d{7})|([S|s|G|g]\d{8})|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8})|([H|h|M|m]\d{8，10})$/;
        if (!reg.test(val)) return "请输入正确的证件号";
      }
      return "";
    };
    let validator2 = (val) => {
      if (!val && (val !== "0" || val !== 0)) {
        return "手机号不能为空";
      }
      let reg =
        /^(\+86)?(1[3-9]\d{9})$|^(\+852)?([6|9|5])\d{7}$|^(\+886)?[0][9]\d{8}$|^(\+8536)?\d{7}$/;
      if (!reg.test(val)) return "请输入正确的手机号";
      return "";
    };
    return {
      data: JSON.parse(JSON.stringify(params)),
      error_obj: {},
      isNeedFaceCheck: true,
      loading: false,
      card_type_list: [
        { value: "01", label: "居民身份证" },
        { value: "15", label: "港澳通行证" },
        { value: "17", label: "台湾通行证" },
        { value: "03", label: "护照" },
      ],
      gender_list: [
        { value: 1, label: "男" },
        { value: 2, label: "女" },
      ],
      relationship_list: [
        { value: 2, label: "配偶" },
        { value: 3, label: "父母" },
        { value: 4, label: "子女" },
        { value: 5, label: "其他" },
      ],
      relationship_list1: [{ value: 1, label: "本人" }],
      validator,
      validator2,
    };
  },
  computed: {
    has_card() {
      return getSession() && getSession().has_card;
    },
  },
  components: {
    Item,
  },
  watch: {
    has_card() {
      if (!this.has_card) this.data.relationship_type = 1;
    },
  },
  created() {
    if (!this.has_card) this.data.relationship_type = 1;
  },
  methods: {
    async submit() {
      let params = { ...this.data };
      for (let i in params) {
        let v = params[i];
        let key = i;
        if (params.card_type === "01" && (i === "gender" || i === "birthday")) {
          if (this.error_obj[key]) this.error_obj[key].error = "";
          delete params[i];
          continue;
        }
        if (
          this.error_obj[i] &&
          this.error_obj[i].rules &&
          this.error_obj[i].rules.required
        ) {
          if (!v) {
            this.error_obj[i].error = this.error_obj[i].rules.message;
          } else {
            this.error_obj[i].error = "";
          }
        } else if (
          this.error_obj[i] &&
          this.error_obj[i].rules &&
          this.error_obj[i].rules.validator
        ) {
          this.error_obj[i].error = this.error_obj[i].rules.validator(
            v,
            params.card_type
          );
        }
      }
      this.error_obj = { ...this.error_obj };
      for (let i in this.error_obj) {
        if (this.error_obj[i].error) {
          return;
        }
      }
      try {
        this.loading = true;
        const { data } = await to_submit(params);
        if (data.state_code === 0) {
          if (data.require_face_detection && this.isNeedFaceCheck) {
            this.get_sign(data);
          } else {
            if (!this.has_card) setSession({ ...getSession(), has_card: true });
            this.$router.push({ path: "/success" });
          }
        } else {
          this.$router.push({
            path: "/fail",
            query: { err_msg: encodeURIComponent(data.error_message) },
          });
        }

      } finally {
        this.loading = false;
      }
    },
    async post_data() {
      let params = { ...this.data };
      const { data } = await to_submit(params);
      if (data.state_code === 0) {
        if (!this.has_card) setSession({ ...getSession(), has_card: true });
        this.$router.push({ path: "/success" });
      } else {
        this.$router.push({
          path: "/fail",
          query: { err_msg: encodeURIComponent(data.error_message) },
        });
      }
    },
    get_sign(val) {
      let that = this;
      if (isWechat) {
        // 获取签名
        const url = location.href.split("#")[0];
        wx_tickets({ url }).then(({ data }) => {
          // 权限配置
          wx.config({
            debug: true, // 开启调试模式，调用的所有api的返回值会在客户端alert出来，在pc端时会打印出来，不需要的话可以将true改成false。
            appId: data.app_id, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonce_str, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名
            jsApiList: ["requestWxFacePictureVerify"],
            beta: true,
          });
          // 人脸识别
          wx.ready(function () {
            let params = {
              user_id_key: val.user_id_key,
            };
            wx.invoke(
              "requestWxFacePictureVerify",
              {
                request_verify_pre_info: JSON.stringify(params),
                check_alive_type: 1,
              },
              function (res) {
                if (res.err_code.toString() === "0") {
                  that.data.face_sn = res.verify_result;
                  that.post_data();
                } else {
                  this.$router.push({
                    path: "/fail",
                    query: { err_msg: encodeURIComponent(res.err_msg) },
                  });
                }
              }
            );
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.head {
  height: 176px;
  padding: 25px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #ffffff;
  background: url("../../../assets/img/family-bg.png") right center no-repeat
    #000ca8;
  background-size: contain;
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  padding-bottom: 6px;
}

.main {
  margin: -50px 20px 52px;
  z-index: 100;
  padding: 22px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.11);
  & .main-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #242938;
    text-align: center;
  }
  & .main-introduct {
    font-size: 12px;
    font-weight: 400;
    line-height: 7px;
    color: #d9d9d9;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 24px;
  }
}
.remind {
  font-size: 12px;
  font-weight: 400;
  line-height: 7px;
  color: #707070;
  padding-bottom: 25px;
  width: 100%;
  display: flex;
  align-items: center;
}
.icon {
  color: rgba(0, 136, 255, 1);
  font-size: 16px;
  padding-right: 7px;
  font-weight: bold;
}
.btn {
  width: 100%;
  height: 40px;
  background: #ff7823;
  box-shadow: 0px 2px 8px rgba(255, 0, 0, 0.16);
  border-radius: 9px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}
.remind2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  padding-top: 16px;
  color: rgba(112, 112, 112, 1);
  & span {
    color: rgba(32, 150, 254, 1);
  }
}
.position {
  width: 100%;
  margin: 0 auto;
}
</style>
