import api from '../../../utils/api'

export async function get_sms (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {

  //       }
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/luohu/medical_cards/bind/sms_code/send`, payload)
}
export async function to_submit (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {

  //       }
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/luohu/medical_cards/bind`, payload)
}

